import React from 'react';
import './FAQ.css';

const FAQ = () => {
  return (
    <div className="faq-container">
      
      <div className="faq-item">
        <h2>What is Comic LLM?</h2>
        <p>Comic LLM is an AI-powered tool that creates visual stories in various artistic styles.</p>
   
        <h2>How does it work?</h2>
        <p>When you submit a story idea, we first use an LLM to create a structured narrative with descriptions, characters, and dialogue. Then, we generate images for each panel based on those descriptions.</p>

        <h2>Can I save or share my comics?</h2>
        <p>All generated comics are automatically saved to our gallery, where you can view them later. You can also save the images to your device or share them on social media.</p>
  
        <h2>Why is there weird text, and why do the people sometimes look different</h2>
        <p>With the image models we are using we do not have full control over the images being generated. However we are working on improving this. </p>

        <h2>Why did my comic generation fail?</h2>
        <p>Story generation might fail for content policy violations or technical issues with the AI services. If you encounter an error, try rephrasing your story idea or try again later.</p>
      
        <h2>Contact Us</h2>
        <p>support@greatshots.art</p>
      
      </div>
    </div>
  );
};

export default FAQ; 