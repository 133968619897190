import React, { useState, useEffect } from 'react';
import './NovelDisplay.css';

const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5002';

const NovelDisplay = ({ novel, isLoading, expectedPanelCount }) => {
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [cleanupTriggered, setCleanupTriggered] = useState(false);
  const totalImages = novel?.content?.length || 0;

  // Add warning message if panel count doesn't match expected
  const getPanelCountWarning = () => {
    if (!expectedPanelCount || !totalImages || expectedPanelCount === totalImages) return null;
    return `Received ${totalImages} panels ${expectedPanelCount > totalImages ? 'instead of' : 'rather than'} the expected ${expectedPanelCount}`;
  };

  useEffect(() => {
    if (loadedImages.size === totalImages && totalImages > 0 && !cleanupTriggered) {
      // All images have loaded, schedule cleanup after a delay
      const match = novel.content[0].imageUrl.match(/panel_(\d+)_/);
      const timestamp = match ? match[1] : null;
      
      if (!timestamp) {
        console.error('Could not extract timestamp from URL:', novel.content[0].imageUrl);
        return;
      }

      // Wait 5 minutes before triggering cleanup
      const CLEANUP_DELAY = 5 * 60 * 1000; // 5 minutes in milliseconds
      
      setTimeout(() => {
        console.log('Triggering cleanup for timestamp:', timestamp);
        fetch(`${API_URL}/api/cleanup/${timestamp}`, {
          method: 'POST'
        })
          .then(response => response.json())
          .then(data => {
            console.log('Cleanup response:', data);
            setCleanupTriggered(true);
          })
          .catch(error => console.error('Failed to trigger cleanup:', error));
      }, CLEANUP_DELAY);
    }
  }, [loadedImages, totalImages, novel, cleanupTriggered]);

  if (isLoading) {
    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="progress-bar-fill"></div>
        </div>
        <p>Generating your story... This may take a minute.</p>
      </div>
    );
  }

  if (!novel || !novel.content) {
    return null;
  }

  const warningMessage = getPanelCountWarning();

  console.log('Novel data:', novel);
  console.log('Panel images:', novel.content.map(panel => panel.imageUrl));

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>{novel.title}</h2>
      {warningMessage && (
        <div style={{
          textAlign: 'center',
          color: '#856404',
          backgroundColor: '#fff3cd',
          padding: '0.75rem',
          marginBottom: '1rem',
          borderRadius: '0.25rem'
        }}>
          {warningMessage}
        </div>
      )}
      <div className="novel-display" style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(250px, 100%), 1fr))',
        gap: '1rem',
        padding: '1rem',
        maxWidth: '1200px',
        margin: '0 auto',
      }}>
        {novel.content.map((panel, index) => (
          <div key={index} className="panel" style={{
            backgroundColor: '#f0f0f0',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'auto',
          }}>
            {/* Image container */}
            <div style={{
              flex: '0 0 auto',
              position: 'relative',
              padding: '20px',
              margin: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <img 
                src={panel.imageUrl.startsWith('http') ? panel.imageUrl : `${API_URL}${panel.imageUrl}`}
                alt={`Panel ${index + 1}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  display: 'block',
                  margin: 0,
                  padding: 0,
                  border: 'none',
                }}
                onError={(e) => {
                  console.error(`Failed to load image: ${panel.imageUrl}`);
                  e.target.style.display = 'none';
                }}
                onLoad={() => setLoadedImages(prev => new Set([...prev, index]))}
              />
            </div>
            {/* Text container */}
            <div style={{
              padding: '10px',
              color: '#4a5568',
              textAlign: 'center',
              marginTop: '10px',
              borderTop: 'none',
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              fontSize: '14px',
              height: 'auto',
              fontFamily: "'BackIssuesBB', Arial, sans-serif"
            }}>
              {panel.dialogue || ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NovelDisplay; 