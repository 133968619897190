import React from 'react';

const ImageOverlay = ({ imageUrl, onClose }) => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    cursor: 'pointer'
  };

  const imageStyle = {
    maxWidth: '98vw',
    maxHeight: '98vh',
    objectFit: 'scale-down',
    borderRadius: '8px'
  };

  return (
    <div style={overlayStyle} onClick={onClose}>
      <img 
        src={imageUrl} 
        alt="Full size" 
        style={imageStyle}
      />
    </div>
  );
};

export default ImageOverlay; 