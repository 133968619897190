import React, { createContext, useState } from 'react';

export const OverlayContext = createContext();

export const OverlayProvider = ({ children }) => {
  const [isSignUpVisible, setIsSignUpVisible] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(false);

  const showSignUp = () => {
    setIsSignUpVisible(true);
    setIsLoginVisible(false);
  };

  const showLogin = () => {
    setIsSignUpVisible(false);
    setIsLoginVisible(true);
  };

  const closeOverlay = () => {
    setIsSignUpVisible(false);
    setIsLoginVisible(false);
  };

  return (
    <OverlayContext.Provider
      value={{
        isSignUpVisible,
        isLoginVisible,
        showSignUp,
        showLogin,
        closeOverlay,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
}; 