import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateNovel } from '../services/api';
import ImageOverlay from './ImageOverlay';
import './StoryForm.css';

const StoryForm = ({ onNovelGenerated, isLoading, setIsLoading }) => {
  const [storyIdea, setStoryIdea] = useState('');
  const [style, setStyle] = useState('comic');
  const [customStyle, setCustomStyle] = useState('');
  const [model, setModel] = useState('flux');
  const [panelCount, setPanelCount] = useState(8);
  const [error, setError] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [fullComicUrl, setFullComicUrl] = useState('');
  const [novelGenerated, setNovelGenerated] = useState(false);
  const [showSampleComic, setShowSampleComic] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (!storyIdea.trim()) {
      setError({
        title: 'Story idea required',
        description: 'Please enter a story idea'
      });
      return;
    }
    
    const selectedStyle = style === 'custom' ? customStyle : style;

    setIsLoading(true);
    setShowSampleComic(false);
    try {
      const novel = await generateNovel(storyIdea, selectedStyle, model, panelCount);
      onNovelGenerated(novel);
      console.log('Generated novel:', novel);
      setFullComicUrl(novel.combinedImage);
      setNovelGenerated(true);
    } catch (error) {
      setError({
        title: error.error || 'Error',
        description: error.message || 'Failed to generate story'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewFullComic = () => {
    console.log('Full comic URL:', fullComicUrl);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="story-form-container">
      <form onSubmit={handleSubmit} className="story-form">
        <div className="form-group">
          <textarea
            id="storyIdea"
            value={storyIdea}
            onChange={(e) => setStoryIdea(e.target.value)}
            placeholder="Enter your story idea..."
            rows="4"
            className="story-idea-textarea"
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="style">Style</label>
            <select
              id="style"
              value={style}
              onChange={(e) => setStyle(e.target.value)}
            >
              <option value="comic">Comic Book</option>
              <option value="manga">Manga</option>
              <option value="noir">Noir</option>
              <option value="watercolor">Watercolor</option>
              <option value="children">Children's Book</option>
              <option value="sitcom">Sitcom</option>
              <option value="wimmelbilder">Wimmelbilder</option>
              <option value="film">Feature Film</option>
              <option value="soviet">Soviet propaganda poster</option>
              <option value="editorial">Political Editorial Cartoon</option>
              <option value="newyorker">New Yorker Cartoon</option>
              <option value="farside">Far Side Cartoon</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="model">Model</label>
            <select
              id="model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            >
              <option value="flux">FLUX-schnell</option>
              <option value="sdxl">SDXL-lightning</option>
              <option value="fluxpro">FLUX-pro</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="panelCount">Panels</label>
            <select
              id="panelCount"
              value={panelCount}
              onChange={(e) => setPanelCount(Number(e.target.value))}
            >
              <option value={1}>1 Panel</option>
              <option value={4}>4 Panels</option>
              <option value={8}>8 Panels</option>
              <option value={16}>16 Panels</option>
            </select>
          </div>
        </div>

        {style === 'custom' && (
          <div className="form-group">
            <input
              type="text"
              id="customStyle"
              value={customStyle}
              onChange={(e) => setCustomStyle(e.target.value)}
              placeholder="Enter your custom style..."
            />
          </div>
        )}

        <button
          type="submit"
          className="generate-button"
          disabled={isLoading}
        >
          Generate Comic
        </button>
      </form>

      {error && (
        <div className="error-message">
          <h2>{error.title}</h2>
          <p>{error.description}</p>
        </div>
      )}

      {showSampleComic && !novelGenerated && (
        <div className="sample-comic">
          <img 
            src="https://storage.googleapis.com/elancode-storygallery/story_1734056305140.png" 
            alt="Sample Comic" 
            style={{ maxWidth: '800px', width: '100%', height: 'auto', display: 'block', margin: '20px auto' }}
          />
        </div>
      )}

      {novelGenerated && !isLoading && (
        <button 
          onClick={handleViewFullComic}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          View Full Comic
        </button>
      )}

      {showOverlay && (
        <ImageOverlay imageUrl={fullComicUrl} onClose={handleCloseOverlay} />
      )}
    </div>
  );
};

export default StoryForm; 