import React, { useContext, useEffect } from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { OverlayContext } from './contexts/OverlayContext';
import StoryForm from './components/StoryForm';
import Gallery from './components/Gallery';
import NovelDisplay from './components/NovelDisplay';
import FAQ from './components/FAQ';
import MyComics from './components/MyComics';
import AccountMenu from './components/AccountMenu';
import SignUpOverlay from './components/SignUpOverlay';
import LoginForm from './components/LoginForm';
import './App.css';

function CreateStory() {
  const [novel, setNovel] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleNovelGenerated = (generatedNovel) => {
    console.log('Novel generated:', generatedNovel);
    setNovel(generatedNovel);
  };

  return (
    <div>
      <StoryForm
        onNovelGenerated={handleNovelGenerated}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <NovelDisplay novel={novel} isLoading={isLoading} />
    </div>
  );
}

function App() {
  const { user } = useContext(AuthContext);
  const {
    isSignUpVisible,
    isLoginVisible,
    showSignUp,
    showLogin,
    closeOverlay,
  } = useContext(OverlayContext);

  useEffect(() => {
    console.log('isSignUpVisible:', isSignUpVisible);
  }, [isSignUpVisible]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-container">
          <h1 className="title-font header-title">
            <NavLink
              to="/"
              className="App-link title-font"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              ComicLLM
            </NavLink>
          </h1>
          <div className="nav-account-container">
            <nav className="header-nav">
              <NavLink
                to="/"
                className="App-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
              >
                Create
              </NavLink>
              <NavLink
                to="/gallery"
                className="App-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
              >
                Gallery
              </NavLink>
              <NavLink
                to="/my-comics"
                className="App-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
              >
                My Comics
              </NavLink>
              <NavLink
                to="/faq"
                className="App-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? 'bold' : 'normal',
                })}
              >
                Help
              </NavLink>
            </nav>
            <AccountMenu onSignUpClick={showSignUp} onLoginClick={showLogin} />
          </div>
        </div>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<CreateStory />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/my-comics" element={<MyComics />} />
          <Route path="/faq" element={<FAQ />} />
          {/* Add other routes as needed */}
        </Routes>
      </main>
      <footer className="App-footer">
        <a
          href="https://discord.gg/7X39EqZD"
          className="App-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/discord-logo.jpg"
            alt="Join our Discord"
            style={{ height: '35px', verticalAlign: 'middle' }}
          />
        </a>
      </footer>
      <SignUpOverlay
        isVisible={isSignUpVisible}
        onClose={closeOverlay}
        onSwitchToLogin={showLogin}
      />
      <LoginForm
        isVisible={isLoginVisible}
        onClose={closeOverlay}
        onSwitchToSignUp={showSignUp}
      />
    </div>
  );
}

export default App; 