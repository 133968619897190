import React, { useState, useEffect } from 'react';
import api from '../services/api';
import ImageOverlay from './ImageOverlay';
import './Gallery.css';

function Gallery() {
  const [comics, setComics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchGallery = async () => {
      // Clear comics before fetching new data
      setComics([]);
      setLoading(true);
      try {
        const response = await api.get(`/api/gallery?page=${currentPage}&limit=20`);
        setComics(response.data.images);
        setTotalPages(Math.ceil(response.data.totalImages / 20));
        setLoading(false);
      } catch (err) {
        setError('Failed to load gallery');
        setLoading(false);
      }
    };

    fetchGallery();
  }, [currentPage]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseOverlay = () => {
    setSelectedImage(null);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  if (error) return <div>{error}</div>;

  return (
    <div className="gallery-container">

      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="gallery-grid">
            {comics.map((comic, index) => (
              <div key={index} className="gallery-item" onClick={() => handleImageClick(comic.url)}>
                <img src={comic.thumbnailUrl || comic.url} alt={comic.filename} />
              </div>
            ))}
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </>
      )}
      {selectedImage && (
        <ImageOverlay imageUrl={selectedImage} onClose={handleCloseOverlay} />
      )}
    </div>
  );
}

export default Gallery; 