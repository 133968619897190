import axios from 'axios';

console.log('Loading api.js');
console.log('All env variables:', {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_SERVER_URL: process.env.REACT_APP_SERVER_URL,
  defaultURL: 'http://localhost:5002'
});

const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5002';
console.log('Final API_URL:', API_URL);

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const generateNovel = async (storyIdea, style, model, panelCount) => {
  try {
    console.log('API_URL in generateNovel:', API_URL);
    const response = await fetch(`${API_URL}/api/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      body: JSON.stringify({
        storyIdea,
        style,
        model,
        panelCount,
        isPrivate: false  // Default to public, can be made configurable later
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw error;
    }

    return await response.json();
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getImageUrl = (imagePath) => {
  return `${API_URL}${imagePath}`;
};

// Export the Axios instance as the default export
export default api; 