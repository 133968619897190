import React, { useState, useContext } from 'react';
import './SignUpOverlay.css';
import api from '../services/api';
import { AuthContext } from '../contexts/AuthContext';

function SignUpOverlay({ isVisible, onClose, onSwitchToLogin }) {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [email, setEmail]   = useState('');
  const [password, setPassword] = useState('');
  const [error, setError]   = useState('');

  if (!isVisible) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await api.post('/api/signup', { username, email, password });
      const { token } = response.data;

      // Log in the user by storing the token
      login(token);

      // Clear form fields
      setUsername('');
      setEmail('');
      setPassword('');

      // Close the signup overlay
      if (onClose) {
        onClose();
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Signup failed.');
    }
  };

  return (
    <div className="overlay">
      <div className="overlay-content">
        <button onClick={onClose} className="close-button">&times;</button>
        <form onSubmit={handleSubmit} className="sign-up-form">
          <h2>Sign Up</h2>
          {error && <div className="error-message">{error}</div>}
          <label>
            Username
            <input
              type="text"
              placeholder="Enter a username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
          <label>
            Email
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Password
            <input
              type="password"
              placeholder="Enter a password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <div className="button-footer-container">
            <div className="form-footer">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onSwitchToLogin();
                }}
                className="switch-form-button"
              >
                Already have an account? Log in
              </button>
            </div>
            <button type="submit" className="submit-button">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpOverlay;