import React from 'react';
import './ConfirmationOverlay.css';

function ConfirmationOverlay({ message, onConfirm, onCancel }) {
  return (
    <div className="confirmation-overlay">
      <div className="confirmation-content">
        <p>{message}</p>
        <div className="confirmation-buttons">
          <button onClick={onCancel}>Cancel</button>
          <button onClick={onConfirm} className="confirm-button">Delete</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationOverlay; 